/* scrollbar */
/* width */

::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  
/* scrollbar */

html {
    min-height: 100%;
    scroll-behavior: smooth;
}

body {
    padding: 0;
    margin: 0;
}

.ulBadge li p {
    width: 200px;
    margin-left: -28px;
    padding: 7px 0px 7px 28px;
    background-color:lightblue;
    border-radius: 0 10px 10px 0;
}

.btnScrollToTop {
    box-shadow: 2px 3px silver;
}
.btnScrollToTop:active {
    transform: translateY(4px);
    
}

.swal2-container {
  z-index: 999999 !important;
}
